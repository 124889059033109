<template>
    <div>
        <main data-aos="fade" class="pb-5">
        <section class="site-hero-main site-sec pb-0">
            <div class="site-sec-wrap container-xxl flex-column d-flex pt-5 align-items-center">
                <div class="txt mt-4 mb-5">
                    <h1 class="text-white">Season Tickets</h1>
                </div>
            </div>
        </section>
        <section class="tickets-area site-sec">
            <div class="site-sec-wrap container-xxl">
                <div class="row">
                    <div class="col-lg-3">
                        <div class="">
                            <!-- <div class="ads-space d-block rounded bg-primary mb-4 p-1 text-center">
                                <img src="~@/assets/00.png" class="img-fluid" alt="">
                            </div> -->
                            <div class="tickets-area-account">
                                <h5 class="border-bottom pb-2 mb-3">Your Account</h5>
                                <div class="border rounded px-3 py-3">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div class="tickets-area-wrap">
                            <div class="tickets-area-filter border rounded px-2 py-3 mb-4">
                                <div class="site-cats row gx-3 justify-content-end">
                                    <div class="col-auto ms-2 me-auto">
                                        <h4 class="m-0">Filter By Team</h4>
                                    </div>
                                    <div>
                                        <select class="form-control" v-model="organiserId" @change="filterSeasonTickets">
                                            <option v-for="org in organisers" :key="org.id" :value="org.id">{{ org.name }}</option>
                                        </select>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="tickets-area-list row gx-4 gy-4">
                                <div v-for="filteredEvent in seasonPricing" :key="filteredEvent.id" class="com-sm-6 col-lg-4">
                                    <div class="card card-ticket border-0">
                                        <div class="card-header p-0">
                                            <div class="img img-cover rounded">
                                                <img :src="filteredEvent.banner_image" alt="">
                                            </div>
                                            <a @click.prevent="goToEvent(filteredEvent.id)" href="" class="link-to"></a>

                                        </div>
                                        <div class="card-body bg-transparent">
                                            <p class="card-text">Team: <strong>{{filteredEvent.organiser.name}}</strong></p>
                                            <p class="card-text">Title: <strong>{{filteredEvent.name}}</strong> </p>
                                            <p class="card-text">Price: <strong>{{filteredEvent.amount}} RWF</strong></p>
                                            <p class="card-text">valid until: <strong>{{filteredEvent.valid_to}}</strong></p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                             <div class="tickets-area-list row gx-4 gy-4" v-if="loading">
                                    <all-event-card-skeleton v-for="n in 3" :key="n" />
                                   
                                </div>
                            <div class="tickets-area-pagination mt-4 pt-4 border-top">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item" v-for="k in numberOfPages" :key="k">
                                            <a class="page-link" @click.prevent="fetchEvents(categoryId,k)" href="#">{{k}}</a>
                                        </li>                                        
                                        
                                        <li class="page-item">
                                            <a class="page-link" href="#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    </div>
</template>

<script>
import PalmEvent from '../apis/PalmEvent'
import AllEventCardSkeleton from '../components/common/AllEventCardSkeleton.vue'
export default {
    components: {
        AllEventCardSkeleton
    },
    data() {
        return {
            loading: true,
            seasonPricing: [],
            organisers: [],
            numberOfPages: 1,
            categoryId: 'all',
            nextPage: 1,
            previousPage: null,
            organiserId: null
        }
    },
    methods: {
        fetchOrganisers() {
            PalmEvent.getOrganisers().then(
                (result) => {
                    this.organisers = result.data.data
                },
                (error) => {
                    console.log(error.response)
                }
            )
        },

        filterSeasonTickets() {
            console.log(this.organiserId)
            this.fetchEvents(this.organiserId)
            
        },

        fetchEvents(organiser){
            this.organiserIdId=organiser;
            this.loading = true;
            this.nextPage = 1;
            PalmEvent.getSeasonTicketPricing(organiser).then(
                (result) => {
                    let content = result.data.data;
                    this.seasonPricing = content;
                let nexLink = result.data?.links?.next;
                if(nexLink != null){
                    const splitString = nexLink.split("=");
                    this.nextPage = parseInt(splitString[1]);
                }
                else if(nexLink == null){
                    this.nextPage = null;
                }
                this.numberOfPages = result.data?.meta?.last_page;
                console.log(result.data.data)
                this.loading = false;
                },
                (error) => {
                    const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                    console.log(resMessage) 
                    this.loading = false; 
                }
            )
        },
        goToEvent(eventId){
          this.$router.push({ name: "SeasonPrice",params: {id: eventId} });
        },
    },
    created() {
        let orgId = (this.$route.params.organiserId !== undefined) ? this.$route.params.organiserIdId : '';
        if(orgId !== undefined){
            this.organiserIdId = orgId;
        }
        this.fetchEvents(orgId);
        this.fetchOrganisers()
    }
}
</script>