import AOS from 'aos'
/* Animate On Scroll - Initialisation */
AOS.init({
    delay: 400,
    duration: 1000,
    once: true,
    mirror: true,
    anchorPlacement: "center-center",
    disbale: "mobile",
});

/*! Slider Activations */
// var swiper = new Swiper(".catSlider", {
//     slidesPerView: "auto",
//     spaceBetween: 15,
//     freeMode: true,
//     pagination: {
//         el: ".swiper-pagination",
//         clickable: true,
//     },
//     navigation: {
//         nextEl: ".swiper-button-next",
//         prevEl: ".swiper-button-prev",
//     },
// });
