<template>
    <nav class="offcanvas offcanvas-start pt-5" tabindex="-1" id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-body">
            <ul class="nav flex-column">
                <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#">All Events</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link d-flex align-items-center" href="/season-tickets">
                        <i class="bi bi-person-fill"></i>
                        <span class="ms-1">Season Tickets</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link d-flex align-items-center" href="/account">
                        <i class="bi bi-person-fill"></i>
                        <span class="ms-1">Account</span>
                    </a>
                </li>
            </ul>
            <div class="dropdown  site-search rounded-3 bg-white border w-100">
                <form class="">
                    <div class="w-100">
                        <label class="visually-hidden" for="autoSizingInput">Search events</label>
                        <input type="text" class="form-control px-2 py-2 border-0" id="autoSizingInput"
                            placeholder="Search" :value="searchString" @input="searchEvents">
                    </div>
                </form>
                <div class="dropdown-menu" v-bind:class="{ show: showSearchResults}" style="max-height:300px;overflow-y:scroll;">
                    <div class="card card-ticket border-0" v-for="searchEvent in events" :key="searchEvent.id">
                        <div class="row hva mx-2" @click.prevent="goToEvent(searchEvent.id)">
                            <div class="col-md-4">
                            <img height="20px" :src="searchEvent.banner_image" class="img-thumbnail" alt="...">
                            </div>
                            <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">&nbsp;&nbsp;{{searchEvent.name}}</h5>
                                <p class="card-text">&nbsp;&nbsp;{{searchEvent.start}}</p>
                                
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
import PalmEvent from '../../apis/PalmEvent';

export default {
    name: "drawer",
    data() {
        return {
            searchString: "",
        events: [],
        showSearchResults: false
      
        }
    },
    methods: {
        filterEvents(link){
          this.$router.push({name:'Events',params:{categoryId:link}});
      },
      clearSearch(){
          this.searchString ="";
          this.showSearchResults = false;
          this.events = [];
      },
      goToEvent(eventId){
          this.searchString ="";
          this.showSearchResults = false;
          this.$router.push({ name: "Event",params: {id: eventId} });
     },
      searchEvents(event){
          const inputValue = event.target.value;
          this.searchString = inputValue;
          if(inputValue.length > 5){
              PalmEvent.searchEvents(this.searchString).then(
                  (results) => {
                      this.events = results.data.data;
                      this.showSearchResults = true;
                  },
                  (error) => {
                    const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
                    console.log(resMessage) 
                    this.loading = false; 
                }
              )
          }
      }
    }
}
</script>
<style scoped>
    .hva {
        cursor: pointer;
    }
</style>
