import Api from "./Api";

export default {
  register(form) {
    //Api().defaults.baseURL = process.env.VUE_APP_BASE_API_URL
    return Api().post("/user/register", form);
  },

  login(form) {
    return Api().post("/user/login", form);
  },
  auth() {
    return Api().get("/user");
  },
  
  updateProfile(form){
    return Api().post("/user/update-profile",form)
  },
  logout(){
    let current_token = localStorage.getItem("token"); 
    return Api().delete("/user/logout/"+current_token)
  },

  sendPlayerId(id){
    return Api().get("/users/save-onesignal-token/"+id);
  },

  getCaptcha() {
    //Api().defaults.baseURL = process.env.VUE_APP_BASE_URL
    return Api().get("captcha/math");
  }
 
};
