<template>
  <div id="app">
    <main-header />
    <drawer />
    
    <router-view/>
    <main-footer />
  </div>
</template>
<script>
import MainFooter from './components/common/MainFooter.vue'
import MainHeader from './components/common/MainHeader.vue'
import Drawer from './components/common/Drawer.vue'
export default {
  components: {
    MainHeader,
    MainFooter,
    Drawer
  }
}
</script>
<style>

</style>
